/* Header Styles */

// Small screens
header {
    background-color: white;
    position: relative;
    top: 0;
    .logo {
        position: absolute;
        top: 26px;
        left: 20px;
        width: 120px;
    }
    .menu-toggle {
        height: 40px;
        width: 100%;
        position: absolute;
        top: 100px;
        background-color: #CCC;
        text-align: center;
        cursor: pointer;
        .menu-icon {
            display: inline-block;
            vertical-align: text-bottom;
            &::after {
                background-color: $black;
                box-shadow: 0 7px 0 $black,0 14px 0 $black;
            }
        }
        span {
            display: inline-block;
            line-height: 40px;
            margin-left: 2px;
            font-family: "Raleway", sans-serif;
        }
    }
    .menu {
        position: absolute;
        z-index: 10;
        top: 140px;
        width: 100%;
        text-align: center;
        background-color: #CCC;
        display: none;
        overflow: hidden;
        padding-bottom: 0.7rem;
    }
    .yrs {
        width: 130px;
        height: 100px;
        position: absolute;
        top: 0;
        right: 0;
        /* Background pattern from subtlepatterns.com */
        background-image: url(img/pw_maze_white_@2X.png);
        img {
            height: 65%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include valign;
        }
    }
}

.hero {
    background-size: cover !important;
    height: 310px;
    .hero-heading {
        margin-top: 200px;
    }
    h1 {
        text-align: center;
    }
    a {
        background-color: white;
        clear: right;
        img {
            height: 0.7rem;
            margin-left: 5px;
        }
    }
}

.page-header {
    @extend .hero;
    background-position: center center;
    height: 150px;
    .hero-heading {
        margin-top: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

    }
}

.container {
    position: relative;
    top: 140px;
}

// Medium screens - 640px
@media screen and (min-width: map-get($breakpoints, medium)) {
    header {
        border-bottom: 3px solid #f5c71a;
        height: 133px;
        .logo {
            height: 84px;
            width: auto;
            top: 23px;
            left: 24px;
        }
        .menu-toggle {
            top: 133px;
            z-index: 10;
            width: 25%;
            padding-left: 20px;
            padding-right: 20px;
            left: 50%;
            transform: translate(-50%, 0);
        }
        .menu {
            top: 172px;
            width: 25%;
            left: 0;
            right: 0;
            margin: 0 auto;
            li:first-child {
                border-top: none;
            }
        }
        .yrs {
            height: 130px;
            width: 170px;
        }
    }

    .hero {
        height: 400px;
        margin-top: -170px;
        .hero-heading {
            margin-top: 260px;
        }
    }

    .page-header {
        height: 300px;
        .hero-heading {
            width: 100%;
            margin-top: 0;
            margin-top: 10px;
            text-align: center;
            h1 {
                float: none;
                display: inline;
                margin: 0 auto;
            }
        }
    }

    .container {
        top: 170px;
    }
}

// Large screens - 1024px
@media screen and (min-width: map-get($breakpoints, large)) {
    header {
        position: fixed;
        width: 23%;
        height: 100%;
        border-bottom: none;
        border-right: 4px solid #f5c71a;
        float: left;
        .logo {
            position: relative;
            display: block;
            max-width: 80%;
            width: auto;
            height: auto;
            left: auto;
            top: auto;
            margin: 0 auto;
            margin-top: 15%;
            padding-left: 15px;
        }
        .menu-toggle {
            display: none;
        }
        .menu {
            display: block;
            background-color: transparent;
            top: auto;
            margin-top: 50px;
            width: 100%;
            text-align: left;
            padding-left: calc(10% + 10px);
            a {
                padding-top: 0.5rem;
            }
        }
        .yrs {
            position: absolute;
            top: auto;
            bottom: 0;
            width: 100%;
            height: 170px;
            img {
                height: 47%;
            }
        }
    }

    .hero {
        height: 100vh;
        margin-top: 0;
        position: relative;
        .hero-heading {
            position: absolute;
            right: 70px;
            bottom: 70px;
        }
    }

    .page-header {
        height: 400px;
        .hero-heading {
            width: auto;
            top: auto;
            transform: translateY(0);
            bottom: 45px;
            right: auto;
        }
        position: relative;

    }

    .container {
        width: 77%;
        top: 0;
        float: right;
    }
}

// Extra Large screens - 1200px
@media screen and (min-width: map-get($breakpoints, xlarge)) {
    header {
        width: 18%;
    }
    .container {
        width: 82%;
        max-width: none;
    }
}
