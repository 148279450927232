/* Main content styles */

// Small screens

.icons {
    background-color: map-get($foundation-palette, primary);
    padding-top: 30px;
    padding-bottom: 25px;
    text-align: center;
    p {
        font-family: "Raleway";
        font-weight: 800;
        font-size: rem-calc(14);
        color: white;
        margin-bottom: 0;
    }
    img {
        height: 23px;
        margin-bottom: 10px;
    }
    div {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.contact-btn {
    background-image: url(img/pw_maze_dark_@2X.png);
    padding-top: 30px;
    padding-bottom: 35px;
    h2 {
        margin-bottom: 20px;
    }
    .button {
        font-size: 25px;
        font-weight: 800;
        text-transform: uppercase;
        color: black;
        padding: 7px 10px;
        margin-bottom: 0;
    }
}

.content {
    padding-top: 30px;
    padding-bottom: 30px;
}

.home-content {
    background-color: #1A1A1A;
    color: white;
}

.page-content {
    background-color: #F2F2F2;
    color: white;
    p, li {
        color: $black;
    }
    .service-panel {
        background-color: white;
        padding: 25px;
        margin-bottom: 25px;
        overflow: hidden;
        img {
            object-fit: cover;
            object-position: center center;
            height: 125px;
            width: 100%;
            margin-bottom: 25px;
        }
    }
}

// Medium screens - 640px
@media screen and (min-width: map-get($breakpoints, medium)) {
    .icons {
        height: 220px;
        img {
            height: 30px;
            width: auto;
        }
        p {
            font-size: rem-calc(16);
        }
        div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .contact-btn {
        height: 220px;
        div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .content {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .page-content {
        .columns {
            padding-left: 0;
        }
        .service-panel {
            img {
                height: 200px;
            }
        }
    }
}

// Large screens - 1024px
@media screen and (min-width: map-get($breakpoints, large)) {
    .phone {
        width: auto;
        text-align: right;
        position: absolute;
        top: 50px;
        right: 70px;
        p {
            color: white;
            margin-bottom: 0;
            width: auto;
        }
        a {
            background-color: transparent;
            color: white;
            font-size: rem-calc(20);
        }
    }
    .page-content {
        .service-panel {
            padding: 40px 50px;
            margin-bottom: 50px;
            img {
                width: 35%;
                float: right;
                margin-left: 50px;
                margin-bottom: 20px;
            }
        }
    }
}

// Extra Large screens - 1200px
@media screen and (min-width: map-get($breakpoints, xlarge)) {

}
