/* Footer Styles */

// Small screens

footer {
    padding: 0px !important;
    .footer-logo {
        max-width: 200px;
        display: block;
        margin: 30px auto;
    }
    .btns {
        margin-top: 30px;
        margin-bottom: 30px;
        .button {
            width: 100%;
            float: left;
            &:first-child {
                background-color: #CCC;
                font-family: "Raleway", sans-serif;
                font-size: rem-calc(16);
            }
            &:last-child {
                background-color: #999;
                font-family: "Raleway", sans-serif;
                font-size: rem-calc(20);
                font-weight: 800;
                padding-top: 11px;
                padding-bottom: 14px;
            }
            img {
                max-width: 15px;
                vertical-align: top;
                margin-right: 5px;
                margin-top: -3px;
            }
        }
    }
    .copyright {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

// Medium screens - 640px
@media screen and (min-width: map-get($breakpoints, medium)) {
    footer {
        .footer-logo {
            margin-top: 55px;
            margin-left: 0;
            margin-right: 0;
            float: left;
        }
        .copyright {
            margin-top: 50px;
        }
        .btns {
            margin-top: 40px;
            .button {
                float: right;
                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    margin-top: 3px;
                }
            }
        }
    }
}

// Large screens - 1024px
@media screen and (min-width: map-get($breakpoints, large)) {

}

// Extra Large screens - 1200px
@media screen and (min-width: map-get($breakpoints, xlarge)) {
    footer {
        position: relative;
        height: 220px;
        .footer-logo {
            margin: 70px 0;
        }
        .copyright {
            margin: 86px 0;
            text-align: left;
            p {
                margin-left: -50px;
                margin-bottom: 0;
            }
        }
        .btns {
            margin: 98px 0;
            padding-left: 0;
            text-align: right;
            .button {
                float: none;
                display: inline;
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
                white-space: nowrap;
                &:first-child {
                    font-size: rem-calc(14);
                    padding-top: 10px;
                    margin-right: 20px;
                    padding-bottom: 10px;
                }
                &:last-child {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }
        }
    }
}
