/* Typography */

// Small screens
h1 {
    font-family: "Raleway";
    font-size: rem-calc(25);
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -1px;
    white-space: nowrap;
    color: map-get($foundation-palette, primary);
    background-color: black;
    display: block;
    padding: 2px 7px;
    margin-bottom: 0;
}

h2 {
    font-size: rem-calc(25);
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    color: white;
}

h3 {
    font-size: rem-calc(26);
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    color: $black;
}

h4 {
    font-family: "Raleway", sans-serif;
    font-size: rem-calc(26);
    color: map-get($foundation-palette, primary);
    font-weight: 700;
    text-transform: uppercase;
}

p, li {
    font-size: rem-calc(14);
    font-family: "Raleway", sans-serif;
    letter-spacing: 0.7px;
}

a {
    font-family: "Raleway", sans-serif;
}

form input[type="text"], form input[type="email"], form input[type="file"], form textarea {
    font-size: 18px;
    font-family: "Raleway", sans-serif;
    padding: 15px 25px;
    color: $black;
    &::placeholder {
        color: $black;
    }
}

form input[type="text"], form input[type="email"], form input[type="email"], {
    padding: 15px 25px;
    height: auto;
}

form input[type="file"] {
    background-color: white;
    border: 1px solid #cacaca;
}

form .button {
    color: $black;
    font-family: "Raleway", sans-serif;
    padding: 13px 35px;
}

.wpcf7-response-output {
    font-family: "Raleway", sans-serif;
    margin-left: 0 !important;
    color: black;
}

 header .menu a {
    color: $black;
    font-family: "tablet-gothic-condensed";
    text-transform: uppercase;
    font-size: rem-calc(16);
 }

 header .menu a:hover {
     color: $black;
 }

 .hero a {
     font-family: "Raleway";
     font-weight: 800;
     color: $black;
     transition: all 0.3s ease;
 }

 #wpmem_login {
     legend {
         font-family: "Raleway", sans-serif;
         font-weight: 700 !important;
         margin-bottom: 20px !important;
     }
     label {
         font-family: "Raleway", sans-serif;
         margin-left: 2px;
     }
     #rememberme {
         display: none;
     }
     input[type='submit'] {
         @extend .button;
     }
 }


// Medium screens - 640px
@media screen and (min-width: map-get($breakpoints, medium)) {
    h1 {
        font-size: rem-calc(40);
        display: inline-block;
        float: right;
        padding: 2px 14px;
    }
}

// Large screens - 1024px
@media screen and (min-width: map-get($breakpoints, large)) {
    header .menu a {
        color: #666;
    }
}

// Extra Large screens - 1200px
@media screen and (min-width: map-get($breakpoints, xlarge)) {

}
